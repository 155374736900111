var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"a-booking-form-row align-items-center a-booking-form-search-tourists"},[_c('a-tourist-list',{staticClass:"a-tourist-list-contents",attrs:{"items":_vm.tourists,"isDelete":""},on:{"remove-tourist":function ($event) { return _vm.removeTourist($event); }}}),(_vm.tourists.length <= 7)?_c('div',{staticClass:"a-booking-form-col"},[_c('a-select-search',{staticClass:"a-dropdown-hidden a-select-search-tourist",attrs:{"placeholder":_vm.$t('account.booking.content.add_tourist'),"prepend-icon":"user","prepend-icon-size":14,"loading":_vm.loading,"errors":_vm.errors,"outline":""},on:{"input":function($event){return _vm.changeInput($event)},"blur":_vm.changeBlur,"openSelect":function($event){_vm.innerValue.length === 0 && _vm.actionInitial
            ? _vm.changeInput(_vm.innerValue)
            : false},"input-clear":function($event){_vm.clearValue(),
            _vm.innerValue.length === 0 && _vm.actionInitial
              ? _vm.changeInput(_vm.innerValue)
              : false}},scopedSlots:_vm._u([{key:"count",fn:function(){return [(_vm.tourists.length)?_c('p',{staticClass:"a-select-count-text",staticStyle:{"margin-right":"0","margin-left":"16px"}},[_vm._v(" "+_vm._s(_vm.tourists.length)+"/8 ")]):_vm._e()]},proxy:true},{key:"dropdown",fn:function(ref){
              var closeSelect = ref.closeSelect;
return [_c('div',{staticClass:"a-select-search__dropdown--scroll"},[_c('ul',{staticClass:"a-select-search__dropdown--list"},_vm._l((_vm.items),function(item,i){return _c('li',{key:i,staticClass:"a-select-search__dropdown--item",on:{"click":function($event){_vm.changeTourist(item), closeSelect()}}},[_c('a-user',{attrs:{"user":item,"subTitle":item.email,"isLarge":""}})],1)}),0)]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"a-select-search__dropdown--footer"},[_c('p',{staticClass:"a-select-search__dropdown--text"},[_vm._v(" "+_vm._s(_vm.$t('account.booking.content.did_not_find_the_tourist'))+" ")]),_c('span',{staticClass:"app-link green--text",on:{"click":function($event){_vm.addTourist = true}}},[_vm._v(_vm._s(_vm.$t('account.booking.content.register_a_new_tourist')))])])]}}],null,false,2294456808),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})],1):_vm._e()],1),_c('form-tourist',{model:{value:(_vm.addTourist),callback:function ($$v) {_vm.addTourist=$$v},expression:"addTourist"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }