<template>
	<div>
		<div class='a-trips'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-trips__container">
					<trips-header @click='edit = $event' :progress='getProgress'>
						<template v-slot:status>
							<p class='a-trips__header-title bold' v-if='!lastResult'>{{$t('account.search.download_results')}}</p>
							<p class='a-trips__header-title' v-else-if='initialItems.length === 0'>{{$t('account.search.total_number_of_options')}}: <span>(0)</span></p>
							<p class='a-trips__header-title bold' v-else>{{$t('account.search.choose_want.charter-flight')}}</p>
						</template>
						<template v-slot:title>
							<p class='a-trips__header-title'>{{source.directions.map(el => `${el.terminalFrom.code} - ${el.terminalTo.code}`).join(', ')}}{{source.type === "both" ? ", " + `${$_.head(source.directions).terminalTo.code} - ${$_.head(source.directions).terminalFrom.code}` : null}}</p>
						</template>
						<template v-slot:info>
							<p class='a-trips__header-text'>
								{{$moment($_.head(source.directions).dateFrom).format("DD/MM/YYYY")}}
								{{source.type === "both" ? " - " + $moment($_.head(source.directions).dateTo).format("DD/MM/YYYY") : null}}
								{{source.type === "complex_route" ? " - " + $moment($_.last(source.directions).dateFrom).format("DD/MM/YYYY") : null}}
								• {{source.adults}} {{$helpers.declOfNum(source.adults, $t('account.declOfNum.adults'))}}
								{{source.childrenCount ? `, ${source.childrenCount} ${$helpers.declOfNum(source.childrenCount, $t('account.declOfNum.children'))}` : null}}
							</p>
						</template>
					</trips-header>

					<!-- <template v-if='!progress.some(() => true) || initialItems.length === 0'>
						<v-alert
							type='warning'
							text
							dismissible
							v-if='alert.covid'
							@close-alert='alert.covid = false'
							class='v-alert-covid'
						>
							<p class='v-alert-title'>{{$t("account.alert.covid.title")}}</p>
							<div>
								<p class='v-alert-text'>{{$t("account.alert.covid.descr_1")}}
									<a href="" class='v-alert-link'>{{$t("account.alert.learn_more")}}
										<v-icon icon='arrow-right' size='12' />
									</a>
								</p>
							</div>
						</v-alert>
					</template> -->

					<trips-preloader
						name='flights'
						animation='flights'
						:animationDisabled='lastResult'
						:title='!lastResult ? $t("account.search.preloader.charter_flight.checking") : $t("account.search.preloader.charter_flight.no_available")'
						:text='!lastResult ? null : $t("account.search.preloader.we_could_not_find_the_results")'
						v-if='!progress.some(() => true) || initialItems.length === 0'
					>
						<template v-slot:list v-if='lastResult'>
							<v-button small color='green white--text' :to="$i18nRoute({ name: 'charter-flight' })">{{$t('account.search.change_search')}}</v-button>
							<ul class='a-trips-search-list'>
								<li class='a-trips-search-list-item'>{{$t('account.search.try_choose_alternate_dates')}}</li>
								<li class='a-trips-search-list-item'>{{$t("account.search.preloader.flights.another")}}</li>
							</ul>
						</template>
					</trips-preloader>

					<trips-skeleton-card
						v-else-if='loading'
					/>

					<template v-else>
						<div class='row flex-nowrap'>
							<div class="col-auto a-trips-sidebar-col-auto">
								<trips-sidebar
									v-model='mobile_sidebar'
									:items='initialItems'
									:filters='filters'
									:filtersItems='filtersItems'
									@update-filters='updateFilter($event)'
									@reset-filters='resetFilter($event)'
									@clear-filters='filters = {}'
								/>
							</div>
							<div class='col'>
								<div class="a-trips__main">
									<trips-sorting
										v-model='mobile_sorting'
										:items='initialItems'
										:filtersItems='filtersItems'
										:sorting='sorting'
										:active_sorting='active_sorting'
										@active_sorting='$event => (active_sorting = $event, page = 1)'
										@custom_sorting='$event => custom_sorting = $event'
									/>

									<div class='row'>
										<div class="col">
											<v-button block xxsmall outline color='green' class='tablet-show a-card-mobile-btn' @click='mobile_sidebar = true'>{{$t("account.sidebar.filters")}}</v-button>
										</div>
										<div class="col">
											<v-button block xxsmall outline color='green' class='tablet-show a-card-mobile-btn' @click='mobile_sorting = true'>{{$t("account.buttons.sorting")}}</v-button>
										</div>
									</div>

									<div class='a-trips-content'>
										<!-- <v-alert
											type='warning'
											text
											dismissible
											v-if='alert.covid'
											@close-alert='alert.covid = false'
											class='v-alert-covid'
										>
											<p class='v-alert-title'>{{$t("account.alert.covid.title")}}</p>
											<div>
												<p class='v-alert-text'>{{$t("account.alert.covid.descr_1")}}
													<a href="" class='v-alert-link'>{{$t("account.alert.learn_more")}}
														<v-icon icon='arrow-right' size='12' />
													</a>
												</p>
											</div>
										</v-alert> -->

										<!-- <v-alert
											color='orange'
											border='left'
											outlined
										>
											<p class='v-alert-text'>{{$t("account.alert.travel_between", {to: "Францією", from: "Великобританією"})}} <strong>{{$t("account.alert.partially_open")}}. </strong>
												<a href="" class='v-alert-link green--text'>{{$t("account.search.flights.view_restrictions")}}
													<v-icon icon='arrow-right' size='12' />
												</a>
											</p>
										</v-alert> -->

										<!-- <div class='a-trips-chips'>
											<v-chip color='error white--text' size='small'>{{$t("account.search.flights.sold_out")}}</v-chip>
											<v-chip :disabled='true' size='small'>{{$t("account.search.flights.revised")}}</v-chip>
										</div> -->

										<div v-for='(item, i) in paginationItems' :key='i'>
											<trips-card
												type='charterFlight'
												:item='item'
												:check_availability='check_availability'
												@update-comparison='check_availability = $event'
												@open-details='openDetails(item)'
												@open-offer='openOffer(item)'
												:loading_btn='loading_btn'
												:filters='filters'
												@update-filters='updateFilter($event)'
											/>
										</div>

										<v-pagination
											:total='filtersItems.length'
											:page='page'
											:perPage='perPage'
											@update-pagination='page = $event'
											v-if='filtersItems.length'
										/>

										<trips-preloader
											name='flights'
											animation='flights'
											:animationDisabled='true'
											:title='$t("account.search.preloader.charter_flight.no_found")'
											v-if='paginationItems.length === 0'
										/>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<trips-edit
			:title='$t("account.search.title.charter-flight")'
			v-model='edit'
			@input='edit = $event'
		>
			<template>
				<a-home-charter-flight :edit='true' @update='edit = false, getCharterFlightSearch()' />
			</template>
		</trips-edit>

		<trips-card-details
			v-model='card_details'
			:card='active_card'
			@open-offer='addOrder(active_card)'
			:requestName='charterFlightDetails'
			hideTariff
		/>

		<trips-comparison
			:check_availability='check_availability'
			@clear-comparison='check_availability = {}'
			nameRoute='comparison-charter-flight'
		/>
		<trips-time @update-search='charterFlightSearch'
			link='charter-flight'
			:title='$t("account.search.flights.your_flight")'
			:text='$t("account.search.flights.your_trip")'
			:edit="edit"
		/>
	</div>
</template>

<script>
	import TripsHeader from '@/views/account/search/components/header';
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsSkeletonCard from '@/views/account/search/flights/skeleton-card';
	import TripsSidebar from '@/views/account/search/flights/sidebar';
	import TripsSorting from '@/views/account/search/flights/sorting';
	import TripsCard from '@/views/account/search/flights/card';
	import TripsCardDetails from '@/views/account/details/flights';
	import TripsEdit from '@/views/account/search/components/edit';
	import AHomeCharterFlight from "@/views/account/home/charter-flight";
	import TripsTime from '@/views/account/search/components/time';
	import TripsComparison from '@/views/account/search/components/comparison';
	import { getCharterFlightSearch, charterFlightDetails, addOrder, comparisonCheckAvailability, charterFlightSearch } from '@/services/request';
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import _ from 'lodash';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			setTimeout: null,
			loading: false,
			loading_btn: false,
			active_card: null,
			card_details: false,
			initialItems: [],
			filters: {},
      page: 1,
      perPage: 10,
			lastResult: false,
			progress: [],
			mobile_sidebar: false,
			mobile_sorting: false,
			edit: false,
			alert: {
				covid: true
			},
			check_availability: {},
			active_sorting: 0,
			custom_sorting: {}
		}),
		components: {
			TripsHeader,
			TripsPreloader,
			TripsSkeletonCard,
			TripsSidebar,
			TripsSorting,
			TripsCard,
			TripsCardDetails,
			TripsEdit,
			AHomeCharterFlight,
			TripsTime,
			TripsComparison
		},
		computed: {
			sorting() {
				return [
					{id: 0, key: 'TotalFare.Amount', title: i18n.t("account.search.flights.sorting.best")},
					{id: 1, key: 'TotalFare.Amount', title: i18n.t("account.search.flights.sorting.cheapest")},
					{id: 2, key: 'ElapsedTime', title: i18n.t("account.search.flights.sorting.fastest")}
				]
			},
			getProgress() {
				if(!this.progress) return;
				return this.progress.filter(el => el === true).length * 100 / this.progress.length;
			},
			source() {
				if(!this.$route.query.source) return;
				return JSON.parse(this.$route.query.source);
			},
			filtersItems() {
				return _.flattenDeep(Object.values(this.filters)).length ? this.updateItems() : this.initialItems;
			},
			paginationItems() {
				let ET = this.filtersItems.map(el => ({...el, ElapsedTime: _.sumBy(el.Itineraries, "ElapsedTime")}));

				if(this.active_sorting === 3) {
					let key = this.custom_sorting.time === "departure" ? `Itineraries[${this.custom_sorting.airport}].DepartureDT` : `Itineraries[${this.custom_sorting.airport}].ArrivalDT`
					let sortOrder = this.custom_sorting.interval === "earliest" ? 'asc' : 'desc'

					return _.orderBy(
						_.sortBy(ET, (el) => this.$moment(_.get(el, key)).valueOf()
					), (el) => this.$moment(_.get(el, key)).valueOf(), sortOrder)
						.slice(this.page === 1 ? 0 : this.perPage * (this.page - 1), this.perPage * this.page)
				} else {
					return _.sortBy(ET, this.sorting.find(el => el.id === this.active_sorting).key).slice(this.page === 1 ? 0 : this.perPage * (this.page - 1), this.perPage * this.page)
				}
			}
		},
		created() {
			this.getCharterFlightSearch();
			this.comparisonCheckAvailability();
		},
		methods: {
			charterFlightDetails,
			updateFilter($event) {
				this.filters = {...this.filters, ...$event};
				this.page = 1;
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
				this.updateItems();
			},
			resetFilter(name) {
				delete this.filters[name];
				this.updateFilter();
			},
			filterArray(array, filters) {
				const filterKeys = Object.keys(filters);
				return array.filter((item) => filterKeys.every((key) => {
					if(filters[key] === null || filters[key].length === 0) return item;

					if(key == 'Itineraries[0].ElapsedTime') {
						return filters[key] >= _.get(item, key);
					}

					if(key.indexOf("sliders") !== -1) {
						let DT = (this.$moment(_.get(item, key.replace('sliders.', ''))).hour() * 60) + this.$moment(_.get(item, key.replace('sliders.', ''))).minute();
						return filters[key][0] <= DT && filters[key][1] >= DT;
					}

					return filters[key].indexOf(String(_.get(item, key))) !== -1
				}));
			},
			updateItems() {
				return this.filterArray(this.initialItems, this.filters)
			},
			getCharterFlightSearch(loading) {
				this.loading = loading ?? !(this.progress.some(() => true) && this.initialItems.length);

				getCharterFlightSearch(this.$route.params.storageId).then(res => {
					if(res.data !== null) {
						this.initialItems = res.data.offers;

						this.lastResult = res.data.lastResult;
						this.progress = res.data.progress;

						if(!this.lastResult) {
							this.setTimeout = setTimeout(() => this.getCharterFlightSearch(), 2500);
						}
						if(this.progress.some(() => true) && this.initialItems.length) {
							setTimeout(() => this.loading = false, 5000);
						}
					} else {
						this.initialItems = [];
						this.lastResult = true;
						this.progress = [];
					}
				})
			},
			openDetails(item) {
				this.card_details = true;
				this.active_card = item;
			},
			openOffer(item) {
				this.card_details = true;
				this.active_card = item;
			},
			comparisonCheckAvailability() {
				comparisonCheckAvailability({
					type: 'charterFlight',
					orderId: this.$route.params.orderId,
					storageId: this.$route.params.storageId
				}).then(res => this.check_availability = res.data ?? {});
			},
			addOrder(item) {
				this.loading_btn = true;
				let replaceOfferId = this.source.replaceOfferId ? { replaceOfferId: this.source.replaceOfferId } : false;

				addOrder({
					type: "charterFlight",
					orderId: this.$route.params.orderId,
					offerId: item.code,
					storageId: this.$route.params.storageId,
					...replaceOfferId
				}).then(res => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: res.data.id, lang: this.$route.params.lang},
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			charterFlightSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				charterFlightSearch(this.source).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.getCharterFlightSearch(true);
					} else {
						this.$router.push({
							name: "charter-flight-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
					}
					localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, charterFlights: this.form}));
				})
			}
		},
		watch: {
			mobile_sidebar() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-sidebar')
					this.mobile_sidebar ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			},
			mobile_sorting() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-trips__sorting')
					this.mobile_sorting ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			},
			"$route.params.storageId"() {
				this.edit = false;
				this.initialItems = [];
				this.active_card = null;
				this.card_details = false;
				this.page = 1;
				this.lastResult = false;
				this.progress = [];
				this.filters = {};

				this.getCharterFlightSearch();
				this.comparisonCheckAvailability();
			}
		},
		beforeDestroy() {
			clearTimeout(this.setTimeout)
		}
	}
</script>
