<template>
	<div class='a-sidebar-slider'>
		<div class="row">
			<div class="col">
				<p class='a-sidebar-slider-title'>{{title}}</p>
			</div>
			<div class="col-auto" v-if='reset'>
				<p class='app-link green--text' @click='$emit("reset")'>{{$t("account.buttons.cast")}}</p>
			</div>
		</div>
		<p class='a-sidebar-slider-time' v-if='range'>{{titleRange}}</p>
		<p class='a-sidebar-slider-time' v-else>{{$helpers.timeName(min)}} - {{$helpers.timeName(innerValue)}}</p>
		<vue-slider
			v-model="innerValue"
			:height='1'
			:dotSize='20'
			tooltip='none'
			:enable-cross="false"
			:min='min'
			:max='max'
			:clickable='false'
			@drag-end='$emit("change", innerValue)'
			:silent='true'
		/>
	</div>
</template>

<script>
	import VueSlider from 'vue-slider-component';

	export default {
		data: () => ({
			innerValue: null
		}),
		props: ["title", "range", "min", "max", "value", "reset", "type", "items", "currency"],
		components: {
			VueSlider
		},
		computed: {
			titleRange() {
				if(this.currency) {
					return `${this.$helpers.amount(this.innerValue[0], this.currency)} - ${this.$helpers.amount(this.innerValue[1], this.currency)}`
				} else {
					switch (this.type) {
						case 'total': {
							let night = this.$moment(this.items[0].searchData.dateTo).diff(this.$moment(this.items[0].searchData.dateFrom), 'days'),
									currency = this.items[0].offers[0].currency;
							return `${this.$helpers.amount(this.innerValue[0] / night, currency)} - ${this.$helpers.amount(this.innerValue[1] / night, currency)}`
						}
						case 'date':
							return `${this.timeDiff(this.innerValue[0])} - ${this.timeDiff(this.innerValue[1])}`
						default:
							return `${this.innerValue[0]} - ${this.innerValue[1]}`
					}
				}
			}
		},
		created() {
			this.innerValue = this.value
		},
		methods: {
			timeDiff(timestamp) {
				let { h, m } = this.$helpers.time(timestamp);
				return h + ':' + (m < 10 ? '0' + m : m)
			}
		},
		watch: {
			value() {
				this.innerValue = this.value;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-sidebar {
		&-slider {
			padding-bottom: 40px * .8;
			@include minw( $grid-breakpoints-md ) {
				padding-bottom: 40px;
			}
			&-title {
				font-size: 16px * .9;
				font-weight: 400;
				margin-bottom: 8px * .8;
				@include minw( $grid-breakpoints-md ) {
					font-size: 16px;
					margin-bottom: 8px;
				}
			}
			&-time {
				font-size: 14px * .9;
				font-weight: 300;
				color: rgba($black, .6);
				margin-bottom: 4px;
				@include minw( $grid-breakpoints-md ) {
					font-size: 14px;
				}
			}
			.vue-slider {
				margin: 0 11px * .8;
				@include minw( $grid-breakpoints-md ) {
					margin: 0 11px;
				}
			}
		}
	}
</style>
