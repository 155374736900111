<template>
	<div class='a-tariff-item' :class='{"active": isActiveCard}'>
		<div>
			<p class='a-tariff-item-title' v-if='item.name'>{{item.name}}</p>
			<p class='a-tariff-item-type'>{{items_airClass.find(el => el.id === item.airClass) ? items_airClass.find(el => el.id === item.airClass).name : item.airClass}}</p>

			<div class='a-tariff-item-list'>
				<div class='a-card-details-info-content-item' :style='`${option.isAvailable ? "color: #00AF85": ""}`' v-for='(option, i) in (optionsDetails ? item.optionsDetails : item.options)' :key='i'>
					<span class='a-card-details-info-content-dotted' v-if='isDotted'></span>
					<v-icon :icon='option.icon ? option.icon : "info"' size='14' class='a-card-details-info-content-item-icon' v-else />
					<p class='a-card-details-info-content-item-text'>{{option.name}}</p>
				</div>
			</div>
		</div>
		<div v-if='!item.details'>
			<p class='a-tariff-item-total'>{{$helpers.amount(item.trip.TotalFare.Amount, item.trip.TotalFare.CurrencyCode)}}</p>
			<v-button block :outline='!isActiveCard' :color="`${isActiveCard ? 'white--text' : null} green`"
				@click='$emit("check-tariff", item)'
			>
				{{isActiveCard ? $t("account.offers.tariff.continue_with_this_rate") : $t("account.offers.tariff.choose_this_tariff")}}
			</v-button>
		</div>
		<slot />
	</div>
</template>

<script>
	import { items_airClass } from '@/helpers/items';

	export default {
		computed: {
			items_airClass() {
				return items_airClass()
			}
		},
		props: ['item', 'isActiveCard', 'isDotted', 'optionsDetails']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-tariff-item {
		padding: 30px 15px 20px;
		border: 1px solid rgba($black, .15);
		border-radius: 10px;
		margin-bottom: 10px;
		@include minw( $grid-breakpoints-xs ) {
			padding: 40px * .8 30px * .8 30px * .8;
			margin: 0 6px;
			min-height: 100%;
			display: grid;
			grid-template-rows: 1fr auto;
		}
		@include minw( $grid-breakpoints-md ) {
			padding: 40px * .9 30px * .9 30px * .9;
		}
		@include minw( $grid-breakpoints-lg ) {
			padding: 40px 30px 30px;
		}
		&:hover,
		&.active {
			border-color: $green-persian;
		}
		&-title {
			font-size: 22px * .9;
			font-weight: 700;
			margin-bottom: 5px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 10px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 22px;
				margin-bottom: 10px;
			}
		}
		&-type {
			font-size: 16px * .9;
			font-weight: 400;
			color: rgba($black, .4);
			margin-bottom: 25px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 20px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 16px;
				margin-bottom: 20px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				margin-bottom: 20px;
			}
		}
		&-list {
			text-align: left;
		}
		&-total {
			font-size: 20px * .9;
			font-weight: 700;
			margin: 30px 0 20px;
			@include minw( $grid-breakpoints-xs ) {
				margin: 30px * .6 0;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 20px;
				margin: 30px * .8 0;
			}
			@include minw( $grid-breakpoints-lg ) {
				margin: 30px 0;
			}
		}
		.app-btn {
			font-size: 15px;
		}
		&-action {
			position: absolute;
			top: 20px * .6;
			right: 20px * .6;
			@include minw( $grid-breakpoints-md ) {
				top: 20px * .8;
				right: 20px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				top: 20px;
				right: 20px;
			}
			&-icon {
				color: $green-persian;
				cursor: pointer;
				transition: color transition;
				&:hover {
					color: $green-active;
				}
			}
		}
	}
</style>
