<template>
	<div class='a-sidebar' :class='{"open": value}'>
		<div class="a-trips-mobile-header">
			<p class='a-trips-mobile-header-title'>{{$t("account.sidebar.filters")}}</p>
			<p class='app-link green--text' @click='$emit("clear-filters"), createSlider()' v-if='Object.keys(filters).length'>{{$t("account.buttons.cast")}}</p>
			<div class='a-trips-mobile-header-button ml-auto' @click='$emit("input", false)'>
				<v-icon icon='cross' size='17'></v-icon>
			</div>
		</div>

		<div class="a-sidebar-total">
			<div class="row align-items-center">
				<div class="col">
					<p class='a-sidebar-total-title' v-if='filtersItems.length !== items.length'>{{$t("account.sidebar.shown")}} {{ filtersItems.length }} {{$t("account.with")}} {{ items.length }} {{$t("account.sidebar.flights.flights")}}</p>
					<p class='a-sidebar-total-title' v-else>{{$t("account.sidebar.total_number_of_options")}} - {{ items.length }}</p>
				</div>
				<div class="col-auto tablet-hide">
					<v-button outline xxsmall color="green" class='a-sidebar-total-button'
						@click='$emit("clear-filters"), createSlider()'
						v-if='filtersItems.length !== items.length'
					>{{$t("account.buttons.cast_all")}}</v-button>
				</div>
			</div>
		</div>

		<sidebar-items
			:title='$t("account.sidebar.flights.title_transplants")'
			:items="items_transfer"
			:reset='isResetFilter("Stops")'
			@reset='resetFilter("Stops")'
			@update='updateFilter(items_transfer, "Stops", true)'
			:all='{ id: "all-transfer", name: $t("account.sidebar.flights.all_flights") }'
		/>
		<sidebar-items
			:title='$t("account.sidebar.flights.class")'
			:items="items_class"
			:reset='filters["airClass"] && filters["airClass"].length'
			@reset='$emit("reset-filters", "airClass")'
			@update='updateFilter(items_class, "airClass")'
			v-if='source.airClass === null || items_class.length > 1'
		/>
		<sidebar-items
			:title='$t("account.sidebar.flights.baggage")'
			:items="items_baggage"
			:reset='isResetFilter("BaggageIncluded")'
			@reset='resetFilter("BaggageIncluded")'
			@update='updateFilter(items_baggage, "BaggageIncluded", true)'
			:all='{ id: "all-baggage", name: "Всі" }'
		/>
		<sidebar-items
			:title='$t("account.sidebar.flights.airlines")'
			:items="items_airlines"
			:reset='filters["ValidCarrier"] && filters["ValidCarrier"].length'
			@reset='$emit("reset-filters", "ValidCarrier")'
			@update='updateFilter(items_airlines, "ValidCarrier")'
		/>

		<div class="row">
			<div class="col">
				<p class='a-sidebar-title'>{{$t("account.sidebar.flights.travel_time")}}</p>
			</div>
		</div>

		<div v-for='(item, i) in sliders' :key='i'>
			<sidebar-slider
				type='date'
				:title='item.title'
				:min='item.min'
				:max='item.max'
				:value='item.value'
				:range='item.range'
				:enable-cross="false"
				@change="item.value = $event, $emit('update-filters', {[item.key]: $event})"
				:reset='filters[item.key] && (item.range ? (filters[item.key][0] > item.min || filters[item.key][1] < item.max) : filters[item.key] < item.max)'
				@reset='$emit("reset-filters", [item.key]), item.value = (item.range ? [item.min, item.max] : item.max)'
			/>
		</div>

		<div v-for='(item, i) in terminals' :key='i'>
			<sidebar-items
				:title='`${$t("account.sidebar.flights.airport")} - ${i}`'
				:items="item"
				:reset='filters[`OriginLoc.${i}`] && item.find(el => filters[`OriginLoc.${i}`].some(filter => filter === el.id))'
				@reset='$emit("reset-filters", `OriginLoc.${i}`)'
				@update='updateFilter(item, `OriginLoc.${i}`)'
			/>
		</div>

		<div class='a-sidebar-mobile-footer'>
			<v-button xxsmall color='green white--text' class='a-card-mobile-btn' @click='$emit("input", false)'>
				{{$t('account.buttons.choose')}}
			</v-button>
			<p class='a-sidebar-mobile-footer-text' v-if='filtersItems.length !== items.length'>{{$t("account.sidebar.results_found", {length: filtersItems.length})}}</p>
		</div>
	</div>
</template>

<script>
	import SidebarItems from '@/views/account/search/components/sidebar/items';
	import SidebarSlider from '@/views/account/search/components/sidebar/sliders';
	import { items_airClass } from '@/helpers/items';
	import _ from 'lodash'

	export default {
		data: () => ({
			sliders: []
		}),
		props: ["value", "items", "filters", "filtersItems"],
		computed: {
			items_airClass() {
				return items_airClass()
			},
			source() {
				return JSON.parse(this.$route.query.source)
			},
			itineraries() {
				return _.flattenDeep(this.items.map(el => el.Itineraries));
			},
			itinerariesLength() {
				return this.items.map(el => el.Itineraries.length)[0];
			},
			items_transfer() {
				return _.sortBy(this.updateItemsItineraries('Stops', false, this.nameTransfer), "id");
			},
			items_class() {
				return this.updateItems('airClass', false, this.nameClass)
			},
			items_baggage() {
				return this.updateItemsItineraries('BaggageIncluded', false, this.nameBaggage)
			},
			items_airlines() {
				return this.updateItems('ValidCarrier', "ValidCarrierName")
			},
			terminals() {
				let key = 'OriginLoc';

				let City = _.groupBy(
					_.uniqBy(
						_.sortBy(
							_.flattenDeep(
								this.items.map(el => el.Itineraries.map(itinerary => ({...itinerary, TotalFare: el.TotalFare})))
							), "TotalFare.Amount"
						), key
					).map(t => {
						return {
							id: String(_.get(t, key)),
							name: `${_.get(t, key + 'Name')} (${_.get(t, key)})`,
							City: t.City,
							total: this.$helpers.amount(t.TotalFare.Amount, t.TotalFare.CurrencyCode),
							active: this.filters[`${key}.${t.City}`] && this.filters[`${key}.${t.City}`].find(el => el == String(_.get(t, key))) ? true : false,
						}
					}), "City");

				return City
			}
		},
		components: {
			SidebarItems,
			SidebarSlider
		},
		created() {
			this.createSlider();
		},
		methods: {
			createSlider() {
				let group = _.groupBy(this.itineraries, 'City');

				let result = _.map(Object.entries(group), (el, key) => {
					return {
						key: `sliders.Itineraries[${key}].DepartureDT`,
						title: `${this.$t("account.sidebar.flights.departure_time_from")} ${el[0]}`,
						value: [
							(this.$moment(_.minBy(el[1], 'DepartureDT').DepartureDT).hour() * 60) + this.$moment(_.minBy(el[1], 'DepartureDT').DepartureDT).minute(),
							(this.$moment(_.maxBy(el[1], 'DepartureDT').DepartureDT).hour() * 60) + this.$moment(_.maxBy(el[1], 'DepartureDT').DepartureDT).minute()
						],
						min: (this.$moment(_.minBy(el[1], 'DepartureDT').DepartureDT).hour() * 60) + this.$moment(_.minBy(el[1], 'DepartureDT').DepartureDT).minute(),
						max: (this.$moment(_.maxBy(el[1], 'DepartureDT').DepartureDT).hour() * 60) + this.$moment(_.maxBy(el[1], 'DepartureDT').DepartureDT).minute(),
						range: true
					}
				})

				this.sliders = [
					...result,
					{
						key: "ElapsedTime",
						title: `${this.$t("account.sidebar.flights.flight_duration")}`,
						value: _.maxBy(_.flattenDeep(this.items.map(el => el.Itineraries.map(itinerary => itinerary.ElapsedTime)))),
						min: _.minBy(_.flattenDeep(this.items.map(el => el.Itineraries.map(itinerary => itinerary.ElapsedTime)))),
						max: _.maxBy(_.flattenDeep(this.items.map(el => el.Itineraries.map(itinerary => itinerary.ElapsedTime)))),
						range: false
					}
				]
			},
			updateItems(key, name, func) {
				return _.uniqBy(this.items, key).map(t => {
					return {
						id: String(_.get(t, key)),
						name: func ? func(_.get(t, key)) : _.get(t, (name ?? key)),
						total: this.$helpers.amount(t.TotalFare.Amount, t.TotalFare.CurrencyCode),
						active: this.filters[key] && this.filters[key].find(el => el == String(_.get(t, key))) ? true : false,
						// disabled: !(this.filtersItems.find(el => _.get(el, key) == _.get(t, key)))
					}
				})
			},
			updateItemsItineraries(keyShort, name, func) {
				let key = `Itineraries.[0].${keyShort}`;
				let itineraries = [[], []];

				this.items.map(el => {
					if(el.Itineraries.every((val, i, arr) => val[keyShort] === arr[0][keyShort])) {
						return itineraries[0].push(el)
					} else {
						return itineraries[1].push(el)
					}
				});

				const findItineraries = (items) => _.uniqBy(items, key).map(t => {
					return {
						id: String(_.get(t, key)),
						name: func ? func(_.get(t, key)) : _.get(t, (name ?? key)),
						total: this.$helpers.amount(t.TotalFare.Amount, t.TotalFare.CurrencyCode),
						active: this.filters[key] && this.filters[key].find(el => el == String(_.get(t, key))) ? true : false,
						// disabled: !(this.filtersItems.find(el => _.get(el, key) == _.get(t, key)))
					}
				})

				return [...findItineraries(itineraries[0])]
			},
			updateFilter(items, key, array) {
				if(array) {
					for(let i = 0; i < this.itinerariesLength; i++) {
						this.$emit("update-filters", {[`Itineraries.[${i}].${key}`]: items.filter(el => el.active && !el.all).map(el => el.id)});
					}
				} else {
					this.$emit("update-filters", {[key]: items.filter(el => el.active && !el.all).map(el => el.id)});
				}
			},
			isResetFilter(key) {
				for(let i = 0; i < this.itinerariesLength; i++) {
					return this.filters[`Itineraries.[${i}].${key}`] && this.filters[`Itineraries.[${i}].${key}`].length;
				}
			},
			resetFilter(key) {
				for(let i = 0; i < this.itinerariesLength; i++) {
					this.$emit("reset-filters", `Itineraries.[${i}].${key}`)
				}
			},
			nameTransfer(item) {
				switch (item) {
					case 0:
						return `${this.$t("account.sidebar.flights.direct_flights")}`;
					case 1:
						return `1 ${this.$t("account.sidebar.flights.transplantation")}`;
					case 2:
						return `2 ${this.$t("account.sidebar.flights.transplants")}`;
					default:
						return `${this.$t("account.sidebar.flights.second_flights")}`;
				}
			},
			nameBaggage(item) {
				switch (item) {
					case true:
						return `${this.$t("account.sidebar.flights.with_luggage")}`;
					default:
						return `${this.$t("account.sidebar.flights.no_luggage")}`;
				}
			},
			nameClass(item) {
				return this.items_airClass.find(el => el.id == item)?.name ?? item;
			}
		},
		watch: {
			items() {
				this.createSlider();
			}
		}
	}
</script>
