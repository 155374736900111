<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>{{$t('account.details.flights.title')}}</p>
							</div>
							<div class="col-auto">
								<div class='a-card-details-header-button' @click='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<div class='a-card-details-content'>
						<!-- <v-alert
							custom-background='rgba(7,82,68,.03)'
						>
							<p class='v-alert-title'>
								<v-icon icon='tickets' size='20' style='color: rgba(0,0,0,.4); margin-right: 15px; flex-shrink: 0;' />
								{{$t("account.alert.this_flight_has_a_set_of_tickets")}}</p>
							<p class='v-alert-text' style='color: rgba(0,0,0,.5)'>{{$t("account.alert.we_complete_tickets_for_more_flexibility")}}
								<a href="" class='v-alert-link green--text'>{{$t("account.alert.learn_more")}}
									<v-icon icon='arrow-right' size='12' />
								</a>
							</p>
						</v-alert> -->

						<div class='a-card-details-box' v-for='(itinerary, i) in card.Itineraries' :key='i'>
							<p class='a-card-details-date'>{{$moment(itinerary.DepartureDT).format("ddd, DD MMM")}}</p>
							<div v-for='(item, i) in itinerary.Segments' :key='i'>
								<div class='a-card-details-item'>
									<div class='a-card-details-item-header'>
										<div class='a-card-details-item-logo'>

											<img class='a-card-details-item-logo-img' :src="`https://api.BravoTravel.club/api${item.AirlineLogo}`" alt="">
										</div>
										<div class='a-card-details-item-header-content'>
											<div class='row'>
												<div class="col">
													<p class='a-card-details-item-title'>{{item.AirlineName}}</p>
												</div>
												<div class="col-auto">
													<span class='a-card-details-item-chip'>{{item.MarketingAirline}}{{item.AirFlightNumber}}</span>
												</div>
											</div>
											<p class='a-card-details-item-descr'>{{$t('account.details.flights.operated')}} {{item.AirlineName}}</p>
											<p class='a-card-details-item-descr'>{{items_airClass.find(el => el.id == item.Cabin) ? items_airClass.find(el => el.id == item.Cabin).name : item.Cabin}}</p>
										</div>
									</div>

									<card-details-direction
										type='departure'
										:time='`${$moment(item.DepartureDT).format("HH:mm")}`'
										:title='`${item.OriginLocName} (${item.OriginLoc})`'
										:date='$moment(item.DepartureDT).format("ddd, DD MMM")'
										:duration='$helpers.timeName(item.ElapsedTime)'
									/>

									<card-details-direction
										type='arrivale'
										:time='`${$moment(item.ArrivalDT).format("HH:mm")}`'
										:title='`${item.DestinationLocName} (${item.DestinationLoc})`'
										:date='$moment(item.ArrivalDT).format("ddd, DD MMM")'
										:diff-days='$helpers.diffDays(item.DepartureDT, item.ArrivalDT)'
									/>

									<div class='a-card-details-direction-info' v-if='item.SeatsRemaining > 0 && item.SeatsRemaining <= 5'>
										<div class='a-card-details-item-logo'>
											<v-icon icon='seat' size='18' />
										</div>
										<div>
											<p class='a-card-details-direction-info-text'>{{$t('account.details.flights.remains')}} {{item.SeatsRemaining}} {{$helpers.declOfNum(item.SeatsRemaining, $t('account.declOfNum.places'))}} {{$t('account.details.flights.on_this_flight')}}</p>
										</div>
									</div>
								</div>

								<div class='a-card-details-item' v-if='itinerary.Segments[i + 1]'>
									<card-details-direction
										type='stop'
										:time='$helpers.timeName($moment(itinerary.Segments[i + 1].DepartureDT).diff($moment(item.ArrivalDT), "minutes"))'
										:title="`${$t('account.details.flights.stop_at')} ${item.DestinationLocName}`"
										custom-color='rgba(255, 153, 0, .8)'
									/>
								</div>
							</div>
						</div>

						<div class='a-card-details-box' v-if='!hideTariff'>
							<p class='a-card-details-date'>{{$t('account.details.flights.tariff_conditions')}}</p>

							<div class='a-card-details-info'>
								<div v-for='(itinerary, i) in !hideProgress || $route.query.offerId ? card.Itineraries : details.Itineraries' :key='i'>
									<div class='a-card-details-info-item'>
										<div class='a-card-details-info-header'>
											<div class='a-card-details-info-header-logo'>
												<img class='a-card-details-info-header-logo-img' :src="`https://api.BravoTravel.club/api${itinerary.Segments[0].AirlineLogo}`" alt="">
											</div>
											<div class='a-card-details-info-header-content'>
												<p class='a-card-details-info-header-title'>{{itinerary.OriginLocName}}</p>
												<v-icon icon='air' size='14' class='a-card-details-info-header-icon' />
												<p class='a-card-details-info-header-title'>{{itinerary.DestinationLocName}}</p>
											</div>
											<div class='a-card-details-info-header-action' v-if='hideProgress && items_options_details(itinerary).length > 0'>
												<div @click='openBrandedFares(itinerary)'>
													<v-icon icon='info' size='20' class='a-card-details-info-header-action-icon' />
												</div>
											</div>
										</div>

										<div class='a-card-details-info-content'>
											<div class="row">
												<div class="col-6">
													<p class='a-card-details-info-content-title'>
														<span class='skeleton' :style='`width: 70%; height: 12px`' v-if='!hideProgress' />
														<span v-else>{{findAirClass()}}</span>
													</p>
												</div>
												<div class="col-6">
													<template v-if='!hideProgress'>
														<div class='a-card-details-info-content-item' v-for='(option, i) in 5' :key='i'>
															<span class='skeleton' :style='`width: ${100 / (i + .25)}%; height: 12px`' />
														</div>
													</template>
													<template v-else>
														<template v-if='items_options(itinerary).length > 0'>
															<div class='a-card-details-info-content-item' :style='`${hideProgress && option.isAvailable ? "color: #00AF85": ""}`' v-for='(option, i) in items_options(itinerary)' :key='i'>
																<v-icon :icon='option.icon ? option.icon : "info"' size='14' class='a-card-details-info-content-item-icon' />
																<p class='a-card-details-info-content-item-text'>{{option.name}}</p>
															</div>
														</template>
														<template v-else>
															<div class='a-card-details-info-content-item'>
																<v-icon icon='info' size='14' class='a-card-details-info-content-item-icon' />
																<p class='a-card-details-info-content-item-text'>{{$t('account.details.flights.tariff_conditions_no_info')}}</p>
															</div>
														</template>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>

								<!-- <v-alert color='orange' text>
									<div>
										<p class='v-alert-text'>{{$t("account.alert.covid.descr_2")}}
											<a href="" class='v-alert-link'>{{$t("account.alert.learn_more")}}
												<v-icon icon='arrow-right' size='12' />
											</a>
										</p>
									</div>
								</v-alert> -->
							</div>
						</div>
					</div>
					<div class='a-card-details-footer'>
						<div class='a-card-details-footer__divider'>
							<div class='divider' />
							<span class="a-card-details-footer__divider-progress" :style='`width: ${progress}%`' v-if='!hideProgress'></span>
						</div>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-footer-title' v-if='details.TotalFare'>{{$helpers.amount(details.TotalFare.Amount, details.TotalFare.CurrencyCode)}}</p>
							</div>
							<div class="col-auto" v-if='$route.name !== "flights-offer"'>
								<v-button xxsmall color='green white--text' class='a-card-details-footer-button'
									@click='$emit("open-offer")'
									:loading='loading_btn'
								>{{$t('account.buttons.choose')}}</v-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>

		<a-dialog-branded-fares
			v-model='openModal'
			@close-dialog='openModal = false, item = null'
			:card='item'
		/>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import CardDetailsDirection from '@/views/account/details/flights-direction';
	import { items_airClass } from '@/helpers/items';
	import { flightDetails } from '@/services/request';
	import ADialogBrandedFares from '@/views/account/offers/flights/dialogBrandedFares';

	export default {
		data: () => ({
			details: [],
			BrandedFare: [],
			progress: 0,
			hideProgress: false,
			openModal: false,
			item: null
		}),
		props: {
			value: Boolean,
			card: Object,
			requestName: {
				type: Function,
				default: flightDetails
			},
			loading_btn: {
				type: Boolean,
				default: false
			},
			hideTariff: {
				type: Boolean,
				default: false
			}
		},
		components: {
			CardDetailsDirection,
			ADialogBrandedFares
		},
		computed: {
			items_airClass() {
				return items_airClass()
			}
		},
		created() {
			this.initValue();
		},
		methods: {
			flightDetails() {
				setTimeout(() => this.progress = 78, 150);
				this.hideProgress = false;

				this.requestName({storageId: this.$route.params.storageId ?? this.$route.query.storageId, code: this.card.code}).then(res => {
					this.details = res.data;
					this.progress = 100;

					setTimeout(() => {
						this.progress = 0;
						this.hideProgress = true
					}, 2000)
				})
			},
			initValue() {
				if(this.value && !this.$route.query.offerId) {
					this.flightDetails();
				} else if(this.value && this.$route.query.offerId) {
					this.hideProgress = true;

					this.BrandedFare = this.card.BrandedFares.find(el => el.trip.code === this.$route.query.offerId);
					this.details = this.BrandedFare ? this.BrandedFare.trip : this.card
				}
			},
			items_options(itinerary) {
				return this.$route.query.offerId && this.BrandedFare ? this.BrandedFare.options : itinerary.FareOptions;
			},
			items_options_details(itinerary) {
				return this.$route.query.offerId && this.BrandedFare ? this.BrandedFare.optionsDetails : itinerary.FareOptionsDetails;
			},
			openBrandedFares() {
				this.openModal = true

				let BF = this.$route.query.offerId && this.BrandedFare ? this.BrandedFare : this.details.BrandedFares.find(el => el.airClass === this.details.airClass)
				this.item = {
					...BF,
					details: true
				}
			},
			findAirClass() {
				return this.$route.query.offerId && this.BrandedFare
					? items_airClass.find(el => el.id == this.BrandedFare.airClass)?.name
					: items_airClass.find(el => el.id == this.card.airClass)?.name
			},
			closeDialog() {
				if(this.openModal) return;
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				this.initValue();

				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>
