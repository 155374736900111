var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-dropdown',{attrs:{"invalid":_vm.errors,"dropdown-right":_vm.dropdownRight},scopedSlots:_vm._u([{key:"field",fn:function(){return [_c('div',{staticClass:"a-select__field"},[(_vm.title)?_c('div',{staticClass:"selected"},[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder ? _vm.placeholder : _vm.$t('account.booking.content.choose'))+" ")])]),(_vm.appendIcon)?_c('div',{staticClass:"a-select__append-inner"},[_c('v-icon',{attrs:{"icon":_vm.appendIcon,"size":"20l"}})],1):_vm._e()]},proxy:true},{key:"dropdown",fn:function(ref){
var closeSelect = ref.closeSelect;
return [_c('div',{staticClass:"a-people",class:{ 'a-people-compact': _vm.compact }},[_vm._l((_vm.rooms),function(item,i){return _c('div',{key:i},[(_vm.isRooms)?_c('div',{staticClass:"row a-people-header"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"a-people-title"},[_vm._v(" "+_vm._s(_vm.$t('account.booking.content.room'))+" "+_vm._s(i + 1)+" ")])]),(!_vm.isSingleRoom)?_c('div',{staticClass:"col-auto"},[_c('p',{staticClass:"a-people-btn-remove",on:{"click":function($event){return _vm.removeRoom(i)}}},[_vm._v(" "+_vm._s(_vm.$t('account.buttons.remove'))+" ")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row a-people-row",class:{ 'a-people-row-compact': _vm.compact }},[_c('div',{staticClass:"col a-people-col"},[_c('div',{staticClass:"a-people-item"},[_c('a-stepper',{attrs:{"title":_vm.$t('account.booking.content.adults') +
                  " " + (_vm.isInfant ? '(18+)' : ''),"count":item.adults,"minCount":1,"descr":!_vm.compact && !_vm.isInfant
                    ? _vm.$t('account.booking.content.descr_adult')
                    : null},on:{"change":function($event){return _vm.updateAdults($event, i)}}})],1)]),_c('div',{staticClass:"col a-people-col"},[_c('div',{staticClass:"a-people-item"},[_c('a-stepper',{attrs:{"title":_vm.$t('account.booking.content.children') +
                  " " + (_vm.isInfant ? ("(" + (_vm.$t('account.to')) + " 18)") : ''),"count":item.childrenCount,"descr":!_vm.compact && !_vm.isInfant
                    ? _vm.$t('account.booking.content.descr_child')
                    : null},on:{"change":function($event){return _vm.updateChildrenCount($event, i)}}})],1)]),(_vm.isInfant)?_c('div',{staticClass:"col a-people-col"},[_c('div',{staticClass:"a-people-item"},[_c('a-stepper',{attrs:{"title":_vm.$t('account.booking.content.descr_infant'),"count":item.infant},on:{"change":function($event){return _vm.updateInfant($event, i)}}})],1)]):_vm._e()]),_c('div',{staticClass:"row a-people-row a-people-row-children"},_vm._l((item.children),function(child,index){return _c('div',{key:index,staticClass:"col-auto a-people-col a-people-col-children"},[_c('div',{staticClass:"a-people-item-children"},[_c('p',{staticClass:"a-people-label"},[_vm._v(" "+_vm._s(_vm.$t('account.booking.content.age_child'))+": ")]),_c('a-select',{staticClass:"select-age--children",attrs:{"options":_vm.items_age,"placeholder":"-","xxsmall":""},on:{"input":function ($event) { return _vm.updateChildren($event, i, index); }},model:{value:(item.children[index]),callback:function ($$v) {_vm.$set(item.children, index, $$v)},expression:"item.children[index]"}})],1)])}),0)])}),(_vm.isRooms && !_vm.isSingleRoom)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto ml-auto"},[_c('span',{staticClass:"a-people-btn-add",on:{"click":_vm.addRoom}},[_c('v-icon',{attrs:{"icon":"plus","size":"10"}}),_vm._v(" "+_vm._s(_vm.$t('account.booking.content.add_room'))+" ")],1)])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto ml-auto"},[_c('span',{staticClass:"app-link green--text",on:{"click":function($event){$event.stopPropagation();return closeSelect()}}},[_vm._v(_vm._s(_vm.$t('account.booking.content.done')))])])])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }