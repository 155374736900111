<template>
	<div class='a-card-details-direction' :style='customColor ? `color: ${customColor}` : null'>
		<div class='a-card-details-direction-wrap'>
			<div class='a-card-details-item-logo' >
				<v-icon :icon='type' class='a-card-details-item-logo-icon' :style='customColor ? `color: ${customColor}` : null' />
				<div class="a-card-details-direction-more-icon" v-if='type === "departure"'>
					<span>
						<div class="line"></div>
						<div class="dotted"></div>
					</span>
				</div>
			</div>
			<div>
				<div class='a-card-details-direction-content'>
					<p class='a-card-details-direction-time'>{{time}}
						<span class='a-card-title-sup' v-if='diffDays'
							v-tooltip.top="`${$t('account.details.flights.arrival_in')} ${date}`" style='cursor: help'
						>+{{diffDays}}</span>
					</p>
					<p class='a-card-details-item-title'>{{title}}</p>
				</div>
				<div class='a-card-details-direction-more' v-if='type === "departure"'>
					<p class='a-card-details-item-descr'>{{$t('account.details.flights.duration')}}: {{duration}}</p>
				</div>
			</div>
		</div>
		<div class='a-card-details-direction-duration' v-if='date'>
			<template v-if='diffDays'>
				<div class='a-card-details-direction-duration-night'>
					<v-icon icon='night' size='16' class='a-card-details-direction-duration-night-icon' />
					<p class='a-card-details-direction-duration-night-time'><span class='mobile-hide'>{{$t('account.details.flights.arrival_in')}}</span> {{date}}</p>
				</div>
			</template>
			<p class='a-card-details-direction-duration-time' v-else>{{date}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['type', 'time', 'diff-days', 'title', 'duration', 'date', 'custom-color']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-details-direction {
		display: flex;
		&-wrap {
			flex: 1 1 100%;
			display: flex;
		}
		&-content {
			display: flex;
			flex-direction: column;
			@include minw( $grid-breakpoints-xs ) {
				flex-direction: row;
			}
		}
		&-time {
			font-size: 13px;
			font-weight: 400;
			margin-right: 10px;
			display: flex;
			align-items: flex-start;
			flex-shrink: 0;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px * .9;
				line-height: 25px;
				margin-right: 15px * .8;
				width: 65px * .9;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 16px;
				width: 65px;
				margin-right: 15px;
			}
			&-sup {
				position: absolute;
				top: 0;
				left: 100%;
				font-size: 10px;
				font-weight: 400;
				line-height: 12px;
				margin-left: 4px;
				@include minw( $grid-breakpoints-md ) {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
		&-duration {
			flex-shrink: 0;
			&-time {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
					line-height: 25px;
				}
			}
			&-night {
				display: flex;
				align-items: center;
				color: rgba($cyprus, .8);
				line-height: 25px;
				&-icon {
					flex-shrink: 0;
					margin-top: 2px;
					margin-right: 5px;
					@include minw( $grid-breakpoints-xs ) {
						margin-right: 8px;
					}
				}
				&-time {
					font-size: 12px;
					font-weight: 700;
					@include minw( $grid-breakpoints-xs ) {
						font-size: 16px;
					}
				}
			}
		}
		&-more {
			display: flex;
			align-items: center;
			min-height: 28px;
			&-icon {
				width: 24px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 11px 0 12px;
				span {
					width: 2px;
					height: 100%;
					.line {
						height: 100%;
						background-color: rgba($cyprus, .15);
						mask-image: url("../../../assets/line.svg");
						mask-repeat: repeat-y;
					}
					.dotted {
						position: absolute;
						top: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						&:before,
						&:after {
							content: '';
							width: 4px;
							height: 4px;
							background-color: rgba($cyprus, .15);
							border-radius: 50%;
							position: absolute;
							left: -1px;
						}
						&:before {
							top: -6px;
						}
						&:after {
							bottom: -6px;
						}
					}
				}
			}
		}
		&-info {
			display: flex;
			align-items: center;
			color: rgba($red, .8);
			margin-top: 20px * .8;
			@include minw( $grid-breakpoints-md ) {
				margin-top: 20px;
			}
			&-text {
				font-size: 13px;
				font-weight: 400;
			}
		}
	}
</style>
