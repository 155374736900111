<template>
	<div class='row flex-nowrap skeleton-container'>
		<div class="col-auto a-trips-sidebar-col-auto">
			<div class='a-sidebar' style='margin-top: 30px'>
				<div v-for='(item, i) in sidebar' :key='i'>
					<span class='skeleton' style='width: 55%; height: 25px; margin-bottom: 30px'></span>

					<div class='a-sidebar-items'>
						<div class="row" v-for='(el, i) in item' :key='i'>
							<div class="col">
								<span class='skeleton' :style='`width: ${el}%; height: 20px; margin-bottom: 10px`'></span>
							</div>
							<div class="col-auto">
								<span class='skeleton' style='width: 45px; height: 20px; margin-bottom: 10px'></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class='col'>
			<div class="a-trips__main">
				<div class='a-trips-content'>
					<div class="row" v-if='!hide'>
						<div class="col">
							<span class='skeleton' :style='`width: 38%; height: 25px; margin-bottom: 40px`'></span>
						</div>
						<div class="col">
							<span class='skeleton' style='width: 82%; height: 25px; margin-bottom: 40px; margin-left: auto;'></span>
						</div>
					</div>
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			sidebar: [
				[50, 72, 65, 92],
				[50, 72, 65, 50, 79, 57, 53, 63],
				[50, 72, 65, 50, 79, 57, 53, 63]
			]
		}),
		props: ["hide"]
	}
</script>
