<template>
	<div class='a-trips__sorting' :class='{"open": value}' v-if='filtersItems.length'>
		<div class="a-trips-mobile-header">
			<p class='a-trips-mobile-header-title'>{{$t("account.buttons.sorting")}}</p>
			<div class='a-trips-mobile-header-button ml-auto' @click='$emit("input", false)'>
				<v-icon icon='cross' size='17' />
			</div>
		</div>

		<div class='a-trips__sorting-wrap'>
			<div class='a-trips__sorting-item' :class='{active: item.id === active_sorting}' v-for='(item, i) in items_sorting' :key='i' @click='$emit("active_sorting", item.id)'>
				<div class='a-trips__sorting-content'>
					<p class='a-trips__sorting-title'>{{item.title}}
						<v-icon icon='info' size='13' v-if='i === 0' v-tooltip.bottom="{ content: $t('account.search.flights.sorting.best_descr'), classes: 'tooltip-large' }" />
					</p>
					<p class='a-trips__sorting-text'>{{item.total}} · {{item.time}}</p>
				</div>
				<div class='a-trips__sorting-mobile-content' v-if='i === 0'>
					<p class='a-trips__sorting-mobile-content-text'>{{$t('account.search.flights.sorting.best_descr')}}</p>
				</div>
			</div>
			<div class='a-trips__sorting-item' :class='{active: active_sorting === 3}' @click='updateSort()'>
				<div class='a-trips__sorting-content'>
					<p class='a-trips__sorting-title'>{{customTitle}}</p>
					<div class='a-trips__sorting-action tablet-show' style='display: block' :class='{ open: sorting_open }' @click.stop='sorting_open = !sorting_open'>
						<v-icon icon='arrow-down' size='14'/>
					</div>
					<p class='a-trips__sorting-text tablet-hide'>{{customTotal}}</p>
				</div>
			</div>
			<div class='a-trips__sorting-action' :class="{ open: sorting_open }" @click='sorting_open = !sorting_open'>
				<v-icon icon='arrow-down' size='14'/>
			</div>
			<div class='a-trips__sorting-dropdown' :class="{ open: sorting_open }" v-click-outside="closeSort" v-if='sorting_open'>
				<p class='a-trips__sorting-dropdown-title tablet-hide'>{{$t("account.sort_by_time")}}</p>

				<div class='a-trips__sorting-change'>
					<p class='a-trips__sorting-change-button' :class='{"active": interval === "earliest"}' @click='interval = "earliest"'>{{$t('account.search.flights.sorting.earliest')}}</p>
					<p class='a-trips__sorting-change-button' :class='{"active": interval === "latest"}' @click='interval = "latest"'>{{$t('account.search.flights.sorting.latest')}}</p>
				</div>

				<div class='a-trips__sorting-change'>
					<p class='a-trips__sorting-change-button' :class='{"active": time === "departure"}' @click='time = "departure"'>{{$t('account.search.flights.sorting.time')}} {{$t('account.search.flights.sorting.departure')}}</p>
					<p class='a-trips__sorting-change-button' :class='{"active": time === "arrival"}' @click='time = "arrival"'>{{$t('account.search.flights.sorting.time')}} {{$t('account.search.flights.sorting.arrival')}}</p>
				</div>

				<p class='a-trips__sorting-dropdown-sub-title'>{{$t('account.search.flights.sorting.where')}}</p>

				<div class='a-trips__sorting-dropdown-radio'>
					<div v-for='(item, i) in nameAirport' :key='i'>
						<v-radio
							:id="item.key"
							name='radio'
							:label="item.title"
							:value='item.key'
							v-model='airport'
						/>
					</div>
				</div>

				<div class='a-trips__sorting-dropdown-item'>
					<p class='a-trips__sorting-dropdown-item-title'>{{title}}</p>
					<p class='a-trips__sorting-dropdown-item-total'>{{total}}</p>
				</div>

				<v-button small block color='green white--text' @click='updateSort(), $emit("input", false)'>{{$t("account.buttons.sorting")}}</v-button>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash'
	import { i18n } from '@/plugins/i18n'

	export default {
		data: () => ({
			sorting_open: false,
			interval: "earliest",
			time: "departure",
			airport: 0,
			customTitle: null,
			customTotal: null
		}),
		computed: {
			title() {
				let i = this.interval === "earliest" ? i18n.t('account.search.flights.sorting.earliest') : i18n.t('account.search.flights.sorting.latest');
				let t = this.time === "departure" ? i18n.t('account.search.flights.sorting.departure') : i18n.t('account.search.flights.sorting.arrival');
				return `${i} ${t} (${this.nameAirport.find(el => el.key === this.airport).CityCode})`
			},
			total() {
				let key = this.time === "departure" ? `Itineraries[${this.airport}].DepartureDT` : `Itineraries[${_.find(this.nameAirport, (item) => item.key === this.airport).keyArrivalDT}].ArrivalDT`
				let min_max = this.interval === "earliest" ? this.minBy(this.ET, key) : this.maxBy(this.ET, key);

				return this.$helpers.amount(min_max.TotalFare.Amount, min_max.TotalFare.CurrencyCode)
					+ ' · ' +
					this.$helpers.timeName(min_max.Itineraries[0].ElapsedTime)
					// this.$helpers.timeName(_.sumBy(min_max.Itineraries, "ElapsedTime"))
			},
			itineraries() {
				return _.flattenDeep(this.filtersItems.map(el => el.Itineraries));
			},
			ET() {
				return this.filtersItems.map(el => ({...el, ElapsedTime: _.sumBy(el.Itineraries, "ElapsedTime")}));
			},
			nameAirport() {
				let group = _.uniqBy(this.itineraries, 'City');

				let result = _.map(group, (el, key) => {
					return {
						key: key,
						CityCode: el.CityCode,
						City: el.City,
						title: `${el.City} (${el.CityCode})`,
						keyArrivalDT: _.findIndex(group, (item) => item.DestinationLoc === el.OriginLoc)
					}
				})

				return result
			},
			items_sorting() {
				return this.sorting.map(el => {
					return {
						...el,
						total: this.$helpers.amount(this.minBy(this.ET, el.key).TotalFare.Amount, this.minBy(this.ET, el.key).TotalFare.CurrencyCode),
						time: this.$helpers.timeName(this.minBy(this.ET, el.key).Itineraries[0].ElapsedTime)
						// time: this.$helpers.timeName(_.sumBy(this.minBy(this.ET, el.key).Itineraries, "ElapsedTime"))
					}
				})
			}
		},
		props: ["value", "items", "filtersItems", "sorting", "active_sorting"],
		created() {
			this.customSorting()
		},
		methods: {
			minBy(items, key) {
				return _.minBy(items, (el) => this.$moment(_.get(el, key)).valueOf())
			},
			maxBy(items, key) {
				return _.maxBy(items, (el) => this.$moment(_.get(el, key)).valueOf())
			},
			customSorting() {
				this.customTitle = this.title;
				this.customTotal = this.total;
			},
			updateSort() {
				this.$emit("active_sorting", 3)
				this.$emit("custom_sorting", {
					interval: this.interval,
					time: this.time,
					airport: this.airport,
					keyArrivalDT: _.find(this.nameAirport, (item) => item.key === this.airport).keyArrivalDT
				})
				this.customSorting()
				this.sorting_open = false
			},
			closeSort(event) {
				if(event.target.closest('.app-icon')) return;
				this.sorting_open = false
			}
		}
	}
</script>

