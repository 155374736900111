<template>
	<trips-skeleton>
		<div class='a-card' v-for='el in 3' :key='el'>
			<div class='a-card-wrap'>
				<div class='a-card-content'>
					<div class='a-card-item' v-for='item in 2' :key='item'>
						<div class='a-card-logo' style='color: rgba(0, 0, 0, 0.3)'>
							<v-icon icon='skeleton' size='30'></v-icon>
						</div>
						<div class='a-card-clock'>
							<div class="flex align-items-center">
								<div class='flex'>
									<div>
										<p class='a-card-title'><span class='skeleton' :style='`width: 67px; height: 14px`'></span></p>
										<p class='a-card-sub-title'><span class='skeleton' :style='`width: 40px; height: 14px`'></span></p>
									</div>
									<div class='a-card-dots'>
										<span class='a-card-dot'></span>
										<span class='a-card-dot'></span>
									</div>
									<div>
										<p class='a-card-title'><span class='skeleton' :style='`width: 67px; height: 14px`'></span></p>
										<p class='a-card-sub-title'><span class='skeleton' :style='`width: 40px; height: 14px`'></span></p>
									</div>
									<div class='a-card-time'>
										<p class='a-card-text'><span class='skeleton' :style='`width: 53px; height: 9px; margin-bottom: 8px`'></span></p>
										<p class='a-card-text'><span class='skeleton' :style='`width: 53px; height: 14px`'></span></p>
									</div>
								</div>
							</div>
							<div class='a-card-info'><span class='skeleton' :style='`width: 264px; height: 14px; margin-bottom: 30px`'></span></div>
						</div>
					</div>
				</div>
				<div class='a-card-action' style='align-items: flex-start'>
					<div class='a-card-action-total'>
						<p class='a-card-text'><span class='skeleton' :style='`width: 65px; height: 14px; margin-bottom: 8px`'></span></p>
						<p class='a-card-total'><span class='skeleton' :style='`width: 165px; height: 25px`'></span></p>
					</div>
					<div class='a-card-action-skeleton'>
						<span class='skeleton' :style='`width: 100%; height: 60px`'></span>
					</div>
				</div>
			</div>
		</div>
	</trips-skeleton>
</template>

<script>
	import TripsSkeleton from '@/views/account/search/components/skeleton';

	export default {
		components: {
			TripsSkeleton
		}
	}
</script>
