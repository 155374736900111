var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"a-tourist-list"},_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"a-tourist-list-col"},[_c('a-user',{attrs:{"user":item,"subTitle":("" + (item.type === 'children'
          ? item.age === 0
            ? _vm.$t('account.declOfNum.up_to_1_year')
            : ((item.age) + " " + (_vm.$helpers.declOfNum(
                item.age,
                _vm.$t('account.declOfNum.years')
              )))
          : '')),"isBorder":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.isDelete)?_c('div',{staticClass:"a-user--action ml-auto"},[_c('div',{staticClass:"a-user--action--btn",on:{"click":function($event){return _vm.$emit('remove-tourist', i)}}},[_c('v-icon',{attrs:{"icon":"cross","size":"20"}})],1)]):_vm._e()]},proxy:true}],null,true)})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }