<template>
	<div class="a-trips__header">
		<div class="row flex-nowrap align-items-center">
			<div class="col-auto tablet-hide">
				<slot name='status' />
			</div>
			<div class='col a-trips__header-details ml-auto'>
				<div class='row align-items-center' style='flex-wrap: nowrap;'>
					<div class='col col-auto-mobile-custom'>
						<div class='a-trips__header-content'>
							<slot name='title' />
							<slot name='info' />
						</div>
					</div>
					<div class='col-auto'>
						<div class='a-trips__header-button-mobile tablet-show' @click='$emit("click", true)'>
							<v-icon icon='edit' size='20' />
						</div>
						<v-button outline color='green' class='a-trips__header-button tablet-hide' @click='$emit("click", true)'>{{$t("account.buttons.edit")}}</v-button>
					</div>
				</div>
			</div>
		</div>

		<div class='a-trips__header__divider'>
			<div class='divider' />
			<span class="a-trips__header__divider-progress" :style='`width: ${progress}%`' v-if='!hideProgress'></span>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			hideProgress: false
		}),
		props: ["progress"],
		created() {
			this.hide();
		},
		methods: {
			hide() {
				if(this.progress === 100) {
					setTimeout(() => {
						this.hideProgress = true
					}, 2000)
				} else {
					this.hideProgress = false
				}
			}
		},
		watch: {
			progress() {
				this.hide();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trips__header {
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 6;
		background-color: $white;
		margin-bottom: 24px;
		@include minw( $grid-breakpoints-sm ) {
			padding-top: 24px;
		}
		&-details {
			@include minw( $grid-breakpoints-sm ) {
				display: grid;
				justify-content: flex-end;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				flex: 1 1 100%;
				max-width: 100%;
				background-color: $white;
				.row {
					justify-content: space-between;
					align-items: stretch;
				}
			}
		}
		.divider {
			@include minw( $grid-breakpoints-sm ) {
				margin-top: 24px;
			}
		}
		&__divider {
			@include maxw( $grid-breakpoints-sm - 1 ) {
				margin-left: -20px;
				margin-right: -20px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-left: -10px;
				margin-right: -10px;
			}
			&-progress {
				position: absolute;
				top: -1px;
				left: 0;
				height: 3px;
				width: 0%;
				max-width: 100%;
				background-color: $green-persian;
				transition: width 2s $transition-timing-function;
			}
		}
		&-content {
			@include maxw( $grid-breakpoints-sm - 1 ) {
				padding: 12px 0;
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 400;
			@include minw( $grid-breakpoints-sm ) {
				font-size: 18px;
			}
			span {
				color: rgba($green-persian, .8);
			}
			&.bold {
				font-size: 16px;
				font-weight: 700;
			}
		}
		&-text {
			font-size: 14px;
			font-weight: 400;
			color: rgba($black, .5);
			@include minw( $grid-breakpoints-sm ) {
				font-size: 16px;
			}
			&.small {
				font-size: 14px;
			}
		}
		&-button {
			height: 40px;
			min-width: auto;
			&-mobile {
				width: 67px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: -20px;
				cursor: pointer;
				color: $green-persian;
				background-color: $white;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-right: -10px;
				}
				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 1px;
					height: 100%;
					background-color: rgba($black, .1);
				}
			}
		}
	}
	.col-auto-mobile-custom {
		flex: 0 1 auto;
	}
</style>
