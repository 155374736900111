<template>
	<v-dialog :value='value' :width='430' class='a-dialog-tariff-card' class-content='border-radius a-dialog-tariff-card-content' @close-dialog="$emit('close-dialog')">
		<a-tariff-card
			:item='card'
			:isActiveCard='isActiveCard'
			:isDotted='true'
			:optionsDetails='true'
			@check-tariff='$emit("check-tariff", $event)'
		/>
	</v-dialog>
</template>

<script>
	import ATariffCard from '@/views/account/offers/flights/tariff-card';

	export default {
		props: ['value', 'card', 'isActiveCard'],
		components: {
			ATariffCard
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-dialog-tariff-card {
		.app-popup {
			z-index: 20;
		}
		.backdrop {
			z-index: 16;
		}
		&-content {
			padding: 45px 40px 40px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding: 40px 25px 25px;
				max-width: 100% !important;
				border-radius: 0 !important;
			}
		}
		.a-tariff-item {
			margin: 0;
			padding: 0;
			border: none;
			text-align: left;
			&-total {
				text-align: center;
			}
		}
	}
</style>
