<template>
	<div>
		<template v-if='!hideSnack'>
			<transition name='translate-left'>
				<div class='a-trips-snack-time' v-if='search_time_close'>
					<div class="a-trips-snack-time__wrapper">
						<div class="a-trips-snack-time__content">
							<p class='a-trips-snack-time__content-title'>{{$t("account.search.time.title")}} {{timeDiffAbbr(time)}}</p>
							<p class='a-trips-snack-time__content-text'>{{$t("account.search.time.descr", {title: title})}}</p>
						</div>
						<div class="a-trips-snack-time__action">
							<div class='a-trips-snack-time__action-button' @click.stop="restartTime">
								<v-icon icon='cross' size='12' />
							</div>
						</div>
					</div>
				</div>
			</transition>
		</template>

		<v-dialog :value='timeZero' :showCloseCross='false' :width='800' class-content='border-radius' @close-dialog='timeZero = false'>
			<div class='a-trips-dialog-content'>
				<p class='a-trips-dialog-title'>{{customTitleDialog ? customTitleDialog : $t("account.search.time.dialogs.title")}}</p>
				<p class='a-trips-dialog-text'>{{$t("account.search.time.dialogs.descr", {text: text, interval: interval + interval_show})}}</p>
				<div class='a-trips-dialog-action'>
					<div class='a-trips-dialog-col' v-if='link'>
						<v-button block outline small color='green' :to="$i18nRoute({ name: link })">{{$t("account.search.time.start_over")}}</v-button>
					</div>
					<div class='a-trips-dialog-col'>
						<v-button block small color='green white--text' @click.stop='$emit("update-search"), restartTime()'>{{$t("account.search.time.refresh_search")}}</v-button>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data: () => ({
			setTimeout: null,
			search_time_close: false,
			timeZero: false,
			interval: 15,
			interval_show: 5,
			currentTime: null,
			timer: null,
			time: null
		}),
		props: ['title', 'text', 'link', 'hideSnack', 'customTitleDialog', 'edit'],
		created() {
			this.startTime();
		},
		methods: {
			startTime() {
				this.currentTime = this.$moment();
				this.timer = this.$moment().add(this.interval + this.interval_show, "minutes");

				this.setTimeout = setInterval(() => {
					let currentMinutes = this.$moment().diff(this.currentTime, "minutes");
					this.time = this.timer.diff(this.$moment());

					if(currentMinutes >= this.interval + this.interval_show) {
						this.search_time_close = false;
						this.timeZero = true;
						clearTimeout(this.setTimeout);
					} else if(currentMinutes >= this.interval ) {
						this.search_time_close = true;
						this.timeZero = false;
					} else {
						this.search_time_close = false;
						this.timeZero = false;
					}
				}, 1000);
			},
			restartTime() {
				clearTimeout(this.setTimeout);
				this.search_time_close = false;
				this.timeZero = false;
				this.startTime();
			},
			timeDiffAbbr(timestamp) {
				var m = Math.floor((timestamp % 36e5) / 6e4);
				var s = Math.floor((timestamp % 6e4) / 1000);

				let minutes = m > 0 ? m + `${this.$t("account.shortTime.m")} ` : "";
				let seconds = s > 0 ? s + this.$t("account.shortTime.s") : "";

				return minutes + seconds;
			}
		},
		watch: {
			edit() {
				if(!this.edit) this.restartTime();
			}
		},
		beforeDestroy() {
			clearTimeout(this.setTimeout)
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trips {
		&-snack-time {
			position: fixed;
			z-index: 1000;
			bottom: 10px;
			left: 10px;
			width: calc(100% - 20px);
			max-width: 545px;
			@include minw( $grid-breakpoints-xs ) {
				bottom: 20px;
				left: 20px;
				width: calc(100% - 40px);
			}
			&__wrapper {
				display: flex;
				justify-content: space-between;
				background: #F6BD4C;
				padding: 15px;
				@include minw( $grid-breakpoints-xs ) {
					align-items: center;
					padding: 24px;
				}
			}
			&__content {
				&-title {
					font-size: 13px;
					line-height: 16px;
					font-weight: 700;
					margin-bottom: 5px;
					@include minw( $grid-breakpoints-xs ) {
						font-size: 16px;
						line-height: 23px;
						margin-bottom: 2px;
					}
				}
				&-text {
					font-size: 12px;
					line-height: 14px;
					font-weight: 400;
					@include minw( $grid-breakpoints-xs ) {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
			&__action {
				margin-left: 12px;
				@include minw( $grid-breakpoints-xs ) {
					margin-left: 30px;
				}
				&-button {
					padding: 5px;
					cursor: pointer;
				}
			}
		}
		&-dialog {
			&-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: 100%;
				text-align: center;
			}
			&-title {
				font-size: 25px;
				font-weight: 700;
				margin-bottom: 15px;
				@include minw( $grid-breakpoints-sm ) {
					font-size: 20px;
					margin-bottom: 25px;
				}
			}
			&-text {
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 25px;
				@include minw( $grid-breakpoints-sm ) {
					font-size: 16px;
					margin-bottom: 40px;
				}
			}
			&-action {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				@include minw( $grid-breakpoints-xs ) {
					flex-direction: row;
				}
				.app-btn {
					padding: 0 16px;
				}
			}
			&-col {
				width: 100%;
				max-width: 210px;
				&:first-child {
					margin-bottom: 15px;
					@include minw( $grid-breakpoints-xs ) {
						margin-bottom: 0;
						margin-right: 20px;
					}
				}
			}
		}
	}
</style>
