<template>
	<div ref='a-trips-edit' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-top">
			<div class='a-trips-edit' v-if='value'>
				<div class='a-trips-edit-wrap' v-click-outside="closeDialog">
					<div class='a-trips-edit-container app-container a-container'>
						<div class='a-trips-edit-content'>
							<p class='a-trips-edit-title'>{{title}}</p>
							<slot />
						</div>
					</div>
					<div class='a-trips-edit-button' @click='closeDialog'>
						<v-icon icon='cross' size='17' />
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		props: ['title', 'value'],
		methods: {
			closeDialog() {
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-trips-edit')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trips-edit {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 15;
		overflow-x: hidden;
		overflow-y: auto;
		&-container {
			max-width: 1635px;
			margin: auto;
		}
		&-wrap {
			padding: 40px 0;
			background-color: $white;
			@include minw( $grid-breakpoints-xs ) {
				height: auto;
				padding: 55px 0 45px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				min-height: 100%;
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 40px;
			text-align: center;
			@include minw( $grid-breakpoints-xs ) {
				text-align: left;
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 25px;
			}
		}
		&-button {
			position: fixed;
			top: 25px;
			right: 15px;
			padding: 5px;
			cursor: pointer;
			transition: color $transition;
			@include minw( $grid-breakpoints-xs ) {
				top: 30px;
				right: 30px;
			}
			&:hover {
				color: $green-persian;
			}
		}
	}
</style>
